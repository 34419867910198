import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import cloudflareStreamUrl from '../../lib/cloudflareStreamUrl'
import useUnfurl from '../../lib/useUnfurl'
import useUser from '../../lib/useUser'
import Button from '../common/Button'
import Icon from '../common/Icon'
import Loading from '../common/Loading'
import Unfurl from '../common/Unfurl'
import { useAnalytics } from '../context/AnalyticsProvider'
import ResourceImage from './ResourceImage'

const DynamicPDFViewer = dynamic(() => import('./PDFViewer'), { ssr: false })

const RenderResource = ({ item }) => {
  const unfurl = useUnfurl(item)
  const { track } = useAnalytics()

  return (
    <>
      {item?.image && (
        <ResourceImage
          image={item.image}
          altText={item?.alt_text || item?.title}
        />
      )}
      {item?.file && <RenderFile item={item} />}
      {item?.link && unfurl && unfurl.url && (
        <Unfurl
          link={unfurl.url}
          video={unfurl.video}
          image={unfurl.image}
          altText={unfurl.altText || `${unfurl.title} attached image`}
          title={unfurl.title}
          description={unfurl.description}
        />
      )}
      {item?.link && !unfurl && (
        <RenderBasicLink
          item={item}
          onClick={() =>
            track('url_clicked', {
              url: item.link,
              text: item.link,
            })
          }
        />
      )}
      {item?.video_id && <RenderVideoStream item={item} />}
    </>
  )
}

function RenderFile({ item }) {
  const filename = decodeURIComponent(item.file.split('/').pop())
  const extension = filename.split('.').pop().toLowerCase().split('?')[0]
  if (extension.match(/(mp3|wav)/)) {
    return (
      <div className="mt-3 flex flex-col">
        <audio controls className="w-full" src={item.file}>
          <source src={item.file} type={`audio/${extension}`} />
          <track kind="captions" />
        </audio>
        <RenderFileDefault item={item} />
      </div>
    )
  }
  if (extension.match(/(pdf)/)) {
    return (
      <div className="mt-3 flex flex-col">
        <DynamicPDFViewer item={item} />
        <RenderFileDefault item={item} />
      </div>
    )
  }
  return <RenderFileDefault item={item} />
}

function RenderFileDefault({ item }) {
  const urlObj = new URL(item.file)
  const contentDisposition = urlObj.searchParams.get(
    'response-content-disposition'
  )
  const filenameMatch = contentDisposition?.match(
    /filename\*?=["']?(?:UTF-8'')?([^"';]+)/i
  )

  let filename = 'Attached File'
  if (filenameMatch && filenameMatch[1]) {
    filename = decodeURIComponent(filenameMatch[1])
  }
  return (
    <Link href={item?.file}>
      <div className="flex mx-4 md:mx-0 flex-row overflow-hidden items-center my-4 border-2 border-gray-100 rounded cursor-pointer">
        <div className="bg-white px-10 rounded">
          <Icon name="paperclip" color="offBlack" size={28} />
        </div>
        <div className="bg-gray-100 p-4 w-full h-24 flex flex-col justify-center">
          <span className="text-sm font-bold line-clamp-2">
            {filename}
            <Icon name="external-link" color="offBlack" size={16} />
          </span>
          {item.file_size && (
            <span className="text-gray-500 text-xs">{`${(
              item.file_size / 1000000
            ).toFixed(2)}Mb`}</span>
          )}
        </div>
      </div>
    </Link>
  )
}

function RenderBasicLink({ item, onClick }) {
  return (
    <div className="flex flex-col items-start justify-between my-3 px-4 md:px-0">
      <a
        className="text-primary text-xs md:text-sm break-all line-clamp-1 lg:max-w-400 mt-2"
        href={item.link}
        onClick={onClick}
      >
        {item.link}
      </a>

      <Link
        href={item.link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
      >
        <Button className="mt-3 text-offBlack w-full align-middle">
          Open Link <Icon name="chevron-right" color="primary" size={22} />
        </Button>
      </Link>
    </div>
  )
}

function RenderVideoStream({ item }) {
  const streamUrl = cloudflareStreamUrl(item.video_id)
  const { trackData } = useAnalytics()
  const user = useUser()

  const [showLoading, setShowLoading] = useState(true)

  const isNew = new Date(item.created_at) > new Date(Date.now() - 3600000)
  return (
    <div
      className="relative bg-divider rounded overflow-hidden"
      style={{ paddingTop: '56.25%' }}
    >
      {showLoading && (
        <div className="absolute top-0 left-0 w-full items-center align-middle flex flex-col">
          {isNew && (
            <p className="text-gray-600">
              Video may still be processing and will be avilable to view shortly
            </p>
          )}
          <Loading />
        </div>
      )}

      {streamUrl && (
        <ReactPlayer
          key={item.video_id}
          url={streamUrl}
          controls
          width="100%"
          height="100%"
          className="absolute top-0 left-0"
          onReady={() => setShowLoading(false)}
          onStart={() => {
            trackData('stream_played', {
              user,
              video: {
                url: streamUrl,
              },
              played_at: new Date().toISOString(),
            })
          }}
          onEnded={() => {
            trackData('stream_ended', {
              user,
              video: {
                url: streamUrl,
              },
              ended_at: new Date().toISOString(),
            })
          }}
        />
      )}
    </div>
  )
}

export default RenderResource
